import * as React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

function PropertyCardCollectionSkeleton():JSX.Element {
  return (
    <div>
      <Grid container flexDirection="column">
        <Skeleton
          variant="rectangular"
          width={300}
          height={140}
          style={{ borderRadius: 10 }}
        ></Skeleton>
        <Grid container marginBottom={1}>
          <Skeleton width="68%" height={40} />
          <Skeleton width="80%" height={20} />
        </Grid>
        <Skeleton width="50%" height={30} />
        <Skeleton width="80%" height={30} />
        <Skeleton width="80%" height={30} />
      </Grid>
    </div>
  );
}

export default PropertyCardCollectionSkeleton;
